import React, { useEffect, useState } from 'react';
import {
  Card,
  Col,
  Row,
  Button,
  Container,
  FormCheckbox
} from "shards-react";
import { Link,useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Table from './Table';

import {default as queryString} from "qs";
import { useLocation } from "react-router-dom";
import { setDateForTable } from '../../utils/date';
import { formatNumberAmount, getFrequencyLabel, parsingUserProfileSavingObject } from '../../utils/general';
import { SUBSCRIBER_BILLING_ADMIN, SUBSCRIBER_SYNC_TYPE } from '../../utils/constants';
function PlacementData({
  formData,
  setFormData,
  payComponentList,
  userProfile,
  formDataRef,
  sectionformDataRef,
  payItemList,
  syncApi,
  attachApi,
  setAlert,
  userRole,
  saveUserProfile,
  roleSelected,
  confirmAlert,
  setConfirmAlert,
  setAutoSaved,
}) {

  const [viewHistory, setViewHistory] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [tableData, setTableData] = useState([])
  const [disabledAttachPayItem, setDisabledAttachPayItem] = useState(userRole.selectedrole === SUBSCRIBER_BILLING_ADMIN.ADMIN || userRole.selectedrole === SUBSCRIBER_BILLING_ADMIN.BILLING);

  let queryParams = queryString.parse(location.search.replace("?", ""))
  const togleHistory = () => {
    setViewHistory(!viewHistory);
  }
  
  const onChangeSync = async (hrinfoId, isChecked) => {
    if(!isChecked){
      //call an API 
      comparePlacementInfoForSaving(hrinfoId)
    }
  }

  const tableColumns = [
    {
      Header: <span className="text-wrap">Worker Type</span>,
      accessor: "workerType",
      maxWidth: 80,
      minWidth: 80,
      className: "justify-content-center text-center text-wrap text-break pl-2 pr-2",
      bodyClassName: "text-center",
    },
    {
      Header: <span className="text-wrap">Deal Type</span>,
      accessor: "hrDealType",
      maxWidth: 80,
      minWidth:80,
      className: "justify-content-center text-center text-wrap text-break pl-3 pr-3",
      bodyClassName: "text-center",
    },
    {
      Header: <span className="text-wrap">Fee Type</span>,
      accessor: "hrFeeType",
      maxWidth:60,
      minWidth: 60,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
    },
    {
      Header: <span className="text-wrap">Effective Date</span>,
      accessor: "hrEffectiveDate",
      maxWidth: 85,
      minWidth:85,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: (row) => setDateForTable(row.hrEffectiveDate)
    },

    {
      Header: <span className="text-wrap">Fee Currency</span>,
      accessor: "hrPlacementFeeCurrency",
      maxWidth: 80,
      minWidth:80,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
    },
    {
      Header: <span className="text-wrap">Placement Fee</span>,
      accessor: "hrPlacementFee",
      maxWidth: 80,
      minWidth:80,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-right",
      cell:row=>formatNumberAmount(row.hrPlacementFee, "en-US", 2, 2)
    },
    {
      Header: <span className="text-wrap">Placement Fee Frequency</span>,
      accessor: "hrFeeFrequency",
      maxWidth:90,
      minWidth: 90,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: row=> getFrequencyLabel(row.hrFeeFrequency)
    },
    {
      Header: <span className="text-wrap">Pay Component</span>,
      accessor: "hrPayComponentId",
      maxWidth: 95,
      minWidth:95,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: (row) => {
        let payComponent = payComponentList !== undefined && payComponentList !== null ?  payComponentList.filter(payComponent => payComponent.refNum === Number(row.hrPayComponentId)) : [];
       return payComponent.length > 0 ? payComponent[0].name : '';
         
      }
    },
    {
      Header: <span className="text-wrap">Placement Invoice Fee</span>,
      accessor: "hrPlacementInvoiceFee",
      maxWidth:70,
      minWidth: 70,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-right",
      cell : row=> <span className='text-right'>{formatNumberAmount(row.hrPlacementInvoiceFee, "en-US", 2, 2)}</span>
    },

    {
      Header: <span className="text-wrap">Notes</span>,
      maxWidth: 100,
      minWidth:100,
      accessor: "subscriberNote",
      className: "justify-content-center text-center text-wrap text-break",
    },
    {
      Header: <span className="text-wrap">Sync Pay Item</span>,
      accessor: "syncPayItem",
      maxWidth: 70,
      minWidth: 70,
      className: "justify-content-center text-center text-break",
      bodyClassName: "justify-content-center text-center",
      cell : row => <div className={"d-flex justify-content-center text-bold " + ( row.syncPayItem === 1 ? "text-success" : "text-danger" )} >
        <FormCheckbox checked={ row.syncPayItem === 1 ?? false } 
          onChange={() => { if(disabledAttachPayItem){
            onChangeSync(row.hrinfoId, row.syncPayItem === 1 ?? false) 
          }
          else 
            return false;
        }}
        >
          { row.syncPayItem === 1 ? 'Synced' : 'Not Synced'}
        </FormCheckbox></div>
    },
    {
      Header: <span className="text-wrap">Ref#</span>,
      maxWidth: 50,
      minWidth:50,
      accessor: "hrinfoId",
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: (row) => { 
        return (<Link to={{ pathname: `/placement-info-form`, state: { userProfile, role: queryParams.role, row, formData, formDataRef, sectionformDataRef, payItemList, userRole } }}>{row.hrinfoId == 0 ? 'New' : row.hrinfoId}</Link>)
      }    
    }
  ];

  useEffect(() => {
    setTableData([...formData.placementInfos]);
  }, [formData.placementInfos])

  ///----> Compare and Saving part <----///
  const comparePlacementInfoForSaving = async (refNumber) => {
    if (formData.placementInfos.length > sectionformDataRef.placementInfos.length) {
      setConfirmAlert({
        ...confirmAlert,
        confirmMsg: 'Placement is not saved. Would you like to save it now?',
        visible: true,
        onConfirm: async () => {

          setConfirmAlert({ ...confirmAlert, visible: false })
          setAutoSaved(true);
          let profileSaved = await parseModuleObjectForSave();
          if (profileSaved) {
            syncPayItemWithUser(refNumber);
            setAutoSaved(false);
          }
        }
      })
    }
    else {
      syncPayItemWithUser(refNumber);
    }
  }

  async function parseModuleObjectForSave() {
    let savingObject = parsingUserProfileSavingObject(formData, sectionformDataRef, formDataRef, roleSelected);
    if (savingObject !== undefined && savingObject.flag) {
      let response = await saveUserProfile(savingObject.finalData);
      return response;
    }
    else {
      setAlert(savingObject.errorMessage, "danger");
      return savingObject.flag;
    }
  }

  const syncPayItemWithUser = async (hrinfoId) => {
    //call an API 
    let response = await syncApi(formData.userId, SUBSCRIBER_SYNC_TYPE.PLACEMENT, hrinfoId);
    if(response){
      formData.placementInfos = formData.placementInfos.map((x) => {
        if(x.hrinfoId === hrinfoId){
            x.syncPayItem = 1;
            x.payItemRefNum = response;
        }
        return x;
      })
      
      setFormData({...formData})
      sectionformDataRef.placementInfos = sectionformDataRef.placementInfos.map(x =>{
        if(x.hrinfoId === hrinfoId){
          x.syncPayItem = 1;
          x.payItemRefNum = response;
        }
        return x;
      })
    } 
  }

  /// ---- > End < ---- ///

  return (
    <Card id="placementData" small

      className="mb-5 w-100 d-flex personalParent">
      <span className="iconBox"><i className="fas fa-briefcase iconStyle" /></span>
      <h4 className="m-0 section-title headerStyle">Placement Information</h4>
      
      <Container fluid className="file-manager__filters border-bottom">
        <Row className="mb-4">

          <Col lg="10"
            className="d-flex flex-row-reverse">

            <div className='d-flex flex-column' style={{marginRight: '132px', marginTop: '17px'}}>

              <span className='ml-5'>
                <FormCheckbox id="history" name="history"
                  checked={viewHistory} onChange={togleHistory}
                  toggle small><spen className="font-weight-bold text-center">View Placement History</spen></FormCheckbox>
              </span>
              <spen className="font-weight-bold text-center">


              </spen>
              <spen className="font-weight-bold font-italic">Note - Highlighted in blue row is the active Placement Information.</spen>
            </div>
            <div></div>

          </Col>

          <Col lg="2"
            className="pt-0 pl-0 pb-0 pr-2 file-manager__filters__search d-flex align-items-center justify-content-center justify-content-md-end col-6">

            {/*<Link to={{ pathname: `/placement-info-form`, search:`?role=${queryParams.role}`, state: { userProfile, refNum: 0 } }} disabled={userProfile.personalInfo === undefined} state>
              <Button disabled={userProfile.personalInfo === undefined} className="my-2 py-2 mx-2" size='sm'
                theme="accent">Add Placement</Button>
  </Link>*/}

            <Button className="my-2 py-2 pr-0 pl-0 mx-2 w-100" size='sm'
                theme="accent"
                onClick={() => { 

                  history.push({
                    pathname: `/placement-info-form`,
                    search:`?role=${queryParams.role}`,
                    state:{ userProfile, refNum: 0, role:queryParams.role, formData, formDataRef, sectionformDataRef, payItemList, userRole },
                  });
                }}
                ><spen>Add Placement</spen></Button>
          </Col>

        </Row>
        {/*<Row>
          <Col>
            <ReactTable
              columns={tableColumns}
              data={[]}
              pageSize={Number(1)}
              showPageSizeOptions={false}
              resizable={false}
              className="expenseItemsTable"
              noDataText="No results found"
            />
  </Col>
  </Row>*/}

        <div style={{overflow:'scroll'}}>
          {
            (formData && tableData) ? 
              (<Table headers={tableColumns} rows={tableData} viewHistory={viewHistory} />)
             :
              (<Table headers={tableColumns} rows={[]} viewHistory={viewHistory} />)
          }
        </div>
      </Container>
    </Card>
  );
}

export default PlacementData;
