
import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, FormInput, FormSelect, Row } from 'shards-react';
import { connect } from 'react-redux';
import RangeDatePicker from '../../../components/common/RangeDatePicker';
import { setDateForTable, setInputDate } from '../../../utils/date';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { delete_employment_insurance_by_refnum, export_employment_insurance, get_employment_insurance_list } from '../../../redux/actions/subscriber/rateTable';
import ConfirmAlert from '../../../components/common/ConfirmAlert';
import ReactTable from 'react-table';
import { get_country_list, get_region_list } from '../../../redux/actions/user';

const EmploymentInsuranceList = ({ employmentInsuranceList, get_employment_insurance_list, delete_employment_insurance_by_refnum 
    , countryList, regionList, get_country_list, get_region_list, export_employment_insurance
}) => {

    let initialData = {
        premiumRate: '',
        taxType: '',
        countryId: '',
        regionId: '',
        year: '',
        refNum: 0,
        from: '',
        to: '',
        pageSizeOptions: [5, 10, 15, 20, 25, 30],
        pageSize: 10,
    }
    const [formData, setFormData] = useState(initialData);
    const history = useHistory();
    const tableRef = useRef()
    const { pageSize, pageSizeOptions } = formData;
    const [gridData, setGridData] = useState(employmentInsuranceList);
    const [confirmAlert, setConfirmAlert] = useState({
        confirmMsg: "",
        visible: false,
        onConfirm: null
    });

    const tableColumns = [
        {
            Header: "Country", accessor: "countryId", className: "text-center"
            , Cell: row => <div title={row.original.countryName}>{row.original.countryName}</div>
        },
        {
            Header: "State/Province", accessor: "regionId", className: "text-center"
            , Cell: row => <div title={row.original.regionName}>{row.original.regionName}</div>
        },
        {
            Header: "Year", accessor: "year", className: "text-center"
            , Cell: row => <div title={row.original.year}>{row.original.year}</div>
        },
        {
            Header: "Effective Date", accessor: "effectiveDate", className: "text-center",
            Cell: row => setDateForTable(row.original.effectiveDate)
        },
        {
            Header: "Premium Rate (%)", accessor: "premiumRate", className: "text-center"
            , Cell: row => <div title={row.original.premiumRate}>{row.original.premiumRate}</div>
        },
        {
            Header: "End Date", accessor: "endDate", className: "text-center",
            Cell: row => setDateForTable(row.original.endDate)
        },
        {
            Header: "Action",
            accessor: "refNum",
            maxWidth: 150,
            className: "text-center",
            Cell: row => <>
                <span className="p-1">
                    <img src="./icons/edit-square.svg" height="17.5" width="17.5" role="button" className='mr-2' 
                    onClick={() => {
                        history.push({ pathname: `/employment-insurance-add-edit/${row.original.refNum}` })
                    }}></img></span>
                <span className="p-1" role="button" onClick={() => deleteEmploymentInsurance(row.original.refNum)}>
                    <img src="./icons/cross-icon.png" height="17.5" width="17.5" />
                </span>
            </>
        },
        {
            Header: "Ref #", accessor: "refNum", maxWidth: 100, minWidth: 100,
            Cell: row => <Link to={`/employment-insurance-add-edit/${row.original.refNum}`}>{row.original.refNum}</Link>
        }
    ];

    useEffect(() => {
        resetSearch();
        get_country_list();
        get_employment_insurance_list('', '', '', 0, 0);
    }, [])


    var regionListOption = regionList.map((option) => {
        return <option value={option.stateProvId} key={option.stateProvName}>{option.stateProvName}</option>;
    });
    var countryListOption = countryList.map((option) => {
        return <option value={option.countryId} key={option.countryName}>{option.countryName}</option>;
    });

    useEffect(() => {
        if (formData.countryId) {
            get_region_list(formData.countryId);
        }
    }, [formData.countryId]);

    useEffect(() => {
        setGridData(employmentInsuranceList);
    }, [employmentInsuranceList])

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    let onStartDateChanged = (value) => {
        setFormData({ ...formData, from: value })
    }

    let onEndDateChanged = (value) => {
        setFormData({ ...formData, to: value })
    }

    function searchEmploymentInsurance(e) {
        e.preventDefault();
        const fromDate = setInputDate(formData.from);
        const toDate = setInputDate(formData.to);
        get_employment_insurance_list(formData.countryId, formData.regionId, formData.year, fromDate, toDate);
    }

    function resetSearch() {
        setFormData(initialData);
        get_employment_insurance_list('', '', '', 0, 0);
    }

    function deleteEmploymentInsurance(refNum) {
        setConfirmAlert({
            ...confirmAlert,
            confirmMsg: 'Are you sure you want to this Employment Insurance?',
            visible: true,
            onConfirm: async () => {
                let isDeleted = await delete_employment_insurance_by_refnum(refNum);
                if(isDeleted)
                    get_employment_insurance_list(formData.countryId, formData.regionId, formData.year, setInputDate(formData.from), setInputDate(formData.to));           
            }
        })
    }

    function exportEmploymnetInsurance(e) {
        e.preventDefault();
        const fromDate = setInputDate(formData.startDate);
        const toDate = setInputDate(formData.endDate);
        export_employment_insurance(formData.countryId, formData.regionId, formData.year, fromDate, toDate);
    }

    return (
        <Container fluid className="main-content-container p-4">
            <div noGutters
                className="page-header flex-column flex-md-row py-2 d-flex justify-content-end bg-white tableSearch">
                <ConfirmAlert confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />
                <div className="d-flex align-items-center p-4 ml-5 mr-5">
                </div>
                <div className="d-flex align-items-center p-4 ml-5 mr-5">
                </div>

                <div className="d-flex align-items-center p-2">
                    <FormSelect value={formData.countryId} id="countryId" name='countryId' onChange={onChange}>
                        <option value=''>Select Country</option>
                        {countryListOption}
                    </FormSelect>
                </div>

                <div className="d-flex align-items-center p-2">
                    <FormSelect value={formData.regionId} id="regionId" name='regionId' onChange={onChange}>
                        <option value=''>Select State/Province</option>
                        {regionListOption}
                    </FormSelect>
                </div>

                <div className="d-flex align-items-center p-2">
                    <FormInput name='year' value={formData.year}
                        onChange={onChange} placeholder="Year" />
                </div>

                <div className="d-flex align-items-center p-2">
                    <RangeDatePicker className="justify-content-end" name="range"
                        startDate={formData.from}
                        endDate={formData.to}
                        onStartDateChanged={onStartDateChanged} onEndDateChanged={onEndDateChanged} />
                </div>

                <div className="d-flex align-items-center justify-content-end p-2">
                    <Button className="mx-2 py-2 my-2" size='sm' theme="accent"
                        onClick={searchEmploymentInsurance}>Search</Button>

                    <Button onClick={resetSearch} className="mx-2 py-2 my-2"
                        size='sm' theme="accent">Reset</Button>
                </div>
            </div>
            <div className="w-100">
                <Card className="tableCard p-0">
                    <CardHeader className="p-0">
                        <Container fluid className="file-manager__filters border-bottom">
                            <Row>
                                {/* Filters :: Page Size */}
                                <Col lg="12" className="file-manager__filters__rows d-flex justify-content-between">

                                    <span><span>Show</span>
                                        <FormSelect size="sm" name='pageSize'
                                            value={formData.pageSize} className="d-inline"
                                            onChange={onChange}
                                        >
                                            {pageSizeOptions.map((size, idx) => (
                                                <option key={idx} value={size}>
                                                    {size} rows
                                                </option>
                                            ))}
                                        </FormSelect>
                                    </span>
                                    <span>
                                        <Button className="my-2 py-2 mx-2" size='sm'
                                            onClick={exportEmploymnetInsurance} theme="accent">Export
                                        </Button>
                                        <Button className="my-2 py-2" size='sm' theme="accent"
                                            onClick={() => { history.push({ pathname: `/employment-insurance-add-edit/0` })}} >Add Employment Insuance
                                        </Button>
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </CardHeader>
                    <CardBody className="p-0">
                        <div className="">
                            <ReactTable
                                ref={tableRef}
                                columns={tableColumns}
                                data={gridData}
                                pageSize={Number(pageSize)}
                                showPageSizeOptions={false}
                                resizable={false}
                                noDataText="No results found"
                            />
                        </div>
                    </CardBody>
                </Card>
            </div>
        </Container>
    )
}

const mapStateToProps = (state) => ({
    countryList: state.user.countryList,
    regionList: state.user.regionList,
    employmentInsuranceList: state.rateTable?.employmentInsuranceList
});

export default connect(mapStateToProps, {
    get_employment_insurance_list, delete_employment_insurance_by_refnum,
    export_employment_insurance , get_country_list, get_region_list
})
    (EmploymentInsuranceList)
