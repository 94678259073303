import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { delete_payer_configuration, get_payer_configuration_list } from '../../../redux/actions/subscriber/payConfiguration';
import ConfirmAlert from '../../../components/common/ConfirmAlert';
import CustomTable from '../../../components/common/CustomTable';
import { Container } from 'shards-react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const PayerConfigHome = ({ get_payer_configuration_list, delete_payer_configuration, payerList }) => {
    const [formData, setFormData] = useState({
        pageSizeOptions: [5, 10, 15, 20, 25, 30],
        pageSize: 10,
    });

    const { pageSize, pageSizeOptions } = formData;
    const [gridData, setGridData] = useState(payerList);
    const history = useHistory();
    const [confirmAlert, setConfirmAlert] = useState({
        confirmMsg: "",
        visible: false,
        onConfirm: null
    });

    const tableColumns = [
        {
            Header: "Payer List", accessor: "name", className: "justify-content-start"
        },
        {
            Header: "Action",
            accessor: "",
            maxWidth: 120,
            className: "text-center ",
            Cell: row => <>
                <span className="p-1"><img src="./icons/edit-square.svg" height="17.5" width="17.5" className='icon-cursor mr-2' role="button" onClick={() => {
                    history.push({
                        pathname: "/sub-add-edit-payer/" + row.original.payerRefNum,
                    });
                }}></img></span>
                <span className="p-1" role="button" onClick={() => {
                    deletePayerConfiguration(row.original.payerRefNum);
                }}><img src="./icons/cross-icon.png" height="17.5" width="17.5" className='icon-cursor' /></span>
            </>
        }
    ];

    useEffect(() => {
        get_payer_configuration_list();
    }, [])

    useEffect(() => {
        setGridData(payerList);
    }, [payerList])

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const deletePayerConfiguration = (payerRefNum) => {
        setConfirmAlert({
            ...confirmAlert,
            confirmMsg: 'Do you want to Delete the Payer?',
            visible: true,
            onConfirm: async () => {
                await delete_payer_configuration(payerRefNum);
                get_payer_configuration_list();
            }
        })
    }

    return (
        <>
            <ConfirmAlert confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />
            <Container fluid className="main-content-container p-4">
                <div className="w-100">
                    <CustomTable
                        pageSizeOptions={pageSizeOptions}
                        pageSize={pageSize}
                        onChange={onChange}
                        tableColumns={tableColumns}
                        AddButton="Add Payer"
                        addPath="/sub-add-edit-payer/0"
                        tableData={gridData}
                        roundedTop={true}
                        showHeader />
                </div>
            </Container>
        </>
    )
}

const mapStateToProps = (state) => ({
    payerList: state.payConfiguration.payerList
});

export default connect(mapStateToProps, { get_payer_configuration_list, delete_payer_configuration })
    (PayerConfigHome)

