import { combineReducers } from 'redux';
import user from './user';
import alert from './alert';
import errors from "./errors";
import expenses from "./worker/expenses"
import leaves from "./worker/leaves";
import profile from "./worker/profile";
import settings from "./settings";
import clientWorkers from "./client/clientWorkers";
import clientLeaves from "./client/clientLeaves";
import clientExpenses from "./client/clientExpenses";
import subscriberUsers from "./subscriber/subscriberUsers";
import leavePolicy from "./subscriber/leavePolicy";
import subExpenses from "./subscriber/subExpenses";
import home from "./worker/home";
import clientHome from "./client/clientHome";
import subHome from "./subscriber/subHome";

import { payroll } from './subscriber/payroll';
import { invoice } from './subscriber/invoice';
import { sync } from './subscriber/sync';
import { payConfiguration } from './subscriber/payConfiguration';
import payRecord from './subscriber/payRecord';
import { payment } from './subscriber/payments';
import automation from './subscriber/automation';
import { rateTable } from './subscriber/rateTable';
const rootReducer = combineReducers({
  user,
  expenses,
  leaves,
  profile,
  home,
  settings,
  clientHome,
  clientWorkers,
  clientLeaves,
  clientExpenses,
  subHome,
  subscriberUsers,
  subExpenses,
  leavePolicy,
  alert,
  errors,
  payroll,
  invoice,
  sync,
  payConfiguration,
  payRecord,
  payment,
  automation,
  rateTable,
});

export default rootReducer;
