import React, { useEffect, useRef, useState } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Card,
  Col, Container,
  Form, FormTextarea,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import { setDateForTable } from "../../utils/date";
import { formatNotesString, monetaryToNumber, numberToMonetary } from "../../utils/general";
import { useHistory, useParams } from "react-router-dom";
import {
  approve_sub_expense_item,
  approve_sub_mileage_item,
  sub_get_expense_report_for_review,
  reject_sub_expense_item,
  reject_sub_expense_report,
  reject_sub_mileage_item,
  review_sub_expense_report,
  update_expense_item_list,
  review_sub_expense_item, review_sub_mileage_item,
  download_expense_invoice,
  link_invoice_payrecord_to_expense,
  generate_invoice_payrecord_payments
} from "../../redux/actions/subscriber/subExpenses";
import { setAlert } from "../../redux/actions/alert";
import ExpenseItemsCard
  from "../../components/subscriber/review-expense-report/ExpenseItemsCard";
import MileageItemsCard
  from "../../components/subscriber/review-expense-report/MileageItemsCard";
import InfoTooltip from "../../components/common/InfoTooltip";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { get_worker_list_by_client } from '../../redux/actions/subscriber/invoice';
import PayRecordPopUp from "./invoice/PayablePopUp";
import InvoicePopUp from './payable/InvoicePopUp';
import { NavgationPageLocationKey } from '../../utils/constants';
function SubscriberApproveExpenseReport({
  expenseSheet,
  sub_get_expense_report_for_review,
  review_sub_expense_report,
  reject_sub_mileage_item,
  reject_sub_expense_item,
  approve_sub_mileage_item,
  approve_sub_expense_item,
  review_sub_expense_item,
  review_sub_mileage_item,
  update_expense_item_list,
  rejectedExpenseItems,
  rejectedMileageItems,
  initialRejectedExpenseItems,
  initialRejectedMileageItems,
  setAlert,
  changePageMeta,
  title,
  workerList,
  get_worker_list_by_client,
  link_invoice_payrecord_to_expense,
  generate_invoice_payrecord_payments,
  download_expense_invoice
}) {

  let { sheetRefNumber } = useParams();
  const history = useHistory();
  let editableKeyToFocus = useRef(null);

  const [notes, setNotes] = useState("");
  const [totalRejected, setTotalRejected] = useState(0);
  const rejectedStatus = 'Rejected';

  //Need to crack this deal -- added by nipul
  //const [disabledAttachPayItem, setDisabledAttachPayItem] = useState(props.user.selectedrole === SUBSCRIBER_BILLING_ADMIN.ADMIN || props.user.selectedrole === SUBSCRIBER_BILLING_ADMIN.BILLING);

  let apiNotes = (expenseSheet.notes) ? formatNotesString(expenseSheet.notes) + "\n" : "";
  let onNotesChange = (e) => {
    editableKeyToFocus.current = "notes";
    setNotes(e.target.value.substr(apiNotes.length))
  };

  //added Worker Name in the page title once we get response from backend
  useEffect(() => {
    if (Object.keys(expenseSheet).length > 0 && expenseSheet.workerName.trim()) {
      changePageMeta(`${title} - ${expenseSheet.workerName}`)
    }

    // -- Added by Nipul for rejected value
    let totalRejectedExpense = 0;
    let totalRejectedMilage = 0;
    if (expenseSheet?.expenseItemList?.length > 0) {
      totalRejectedExpense = calculateTotalNetAmount(expenseSheet?.expenseItemList, false);
    }

    if (expenseSheet?.mileageItemList?.length > 0) {
      totalRejectedMilage = calculateTotalNetAmount(expenseSheet?.mileageItemList, true);
    }
    setTotalRejected(totalRejectedExpense + totalRejectedMilage)

    setFormData({...formData, invoiceRefNum:expenseSheet.invoiceRef, payRecordRefNum:expenseSheet.payRecordRef})

  }, [expenseSheet]);

  useEffect(() => {
    if (
      sheetRefNumber !== null &&
      sheetRefNumber !== '' &&
      sheetRefNumber !== '0' &&
      sheetRefNumber !== 0
    ) {
      if (!Object.keys(expenseSheet).length)
        sub_get_expense_report_for_review(sheetRefNumber);
    }

  }, [sheetRefNumber]);


  let reviewReport = async () => {
    let reviewStageRejectedMileageItems = rejectedMileageItems.filter(item => initialRejectedMileageItems.indexOf(item))
    let reviewStageRejectedExpenseItems = rejectedExpenseItems.filter(item => initialRejectedExpenseItems.indexOf(item))
    if (!notes && (reviewStageRejectedMileageItems.length || reviewStageRejectedExpenseItems.length))
      setAlert("Please add rejection note for rejected items")
    else {
      await review_sub_expense_report(sheetRefNumber, notes, rejectedExpenseItems, rejectedMileageItems, expenseSheet.expenseItemList, expenseSheet.mileageItemList);
      history.goBack();
    }
  };

  function calculateTotalNetAmount(dataItem, isMileage) {
    let rejected = dataItem.filter(x => x.status === rejectedStatus);
    const sum = rejected.reduce((accumulator, currentValue) => {
      return Number(accumulator) + Number(monetaryToNumber(currentValue[isMileage ? 'amount' : 'netAmount']));
    }, 0);
    return sum;
  }

  const [formData, setFormData] = useState({
    payRecordRefNum: '0',
    invoiceRefNum: '0',
  });
  const [openModal, setOpenModal] = useState(false);
  const [isPageLoad, setIsPageLoad] = useState(true);
  const toggle = () => {
    setIsPageLoad(false);
    setOpenModal(!openModal);
  }

  const setSelectedPayRecordRefNum = (refNum) => {
    if(formData.payRecordRefNum !== refNum){
      setFormData({ ...formData, payRecordRefNum: refNum })
    }
  }

  const [openInvoiceModal, setInoiceOpenModal] = useState(false);
  const toggleInvoice = () => {
    setIsPageLoad(false);
    setInoiceOpenModal(!openInvoiceModal);
  }

  const setSelectedInvoiceRefNum = (refNum) => {
    if(formData.invoiceRefNum !== refNum){
      setFormData({ ...formData, invoiceRefNum: refNum })
    }
  }

  useEffect(() => {
    if (!openInvoiceModal && !isPageLoad) {
      setIsPageLoad(true);
      link_invoice_payrecord_to_expense(sheetRefNumber, 1, formData.invoiceRefNum);
    }
  }, [formData.invoiceRefNum])
  
  useEffect(() => {
    if(!openModal && !isPageLoad){
      setIsPageLoad(true);
      link_invoice_payrecord_to_expense(sheetRefNumber, 2, formData.payRecordRefNum);
    }
  }, [formData.payRecordRefNum])

// Added by nipul for parameter less flow
  function navigateToPayable(e, refId){
      e.preventDefault(); 
      history.push({
          pathname: `/payable`,
          state: { refNum: refId }
      });
      return false;
  }

  function onContextMenuPayableChange(e, refId){
      if(e.type === 'contextmenu'){
          localStorage.setItem(NavgationPageLocationKey.PayableCurrentAddEdit, refId);
      }
  }

  return (
    <Container fluid className="main-content-container px-4 pb-4">

      <PayRecordPopUp
        toggle={toggle}
        openModal={openModal}
        selectedRefs={formData.payRecordRefNum}
        setSelectedRef={setSelectedPayRecordRefNum}
        {...formData}
        workerList={workerList}
      />

      <InvoicePopUp
        toggle={toggleInvoice}
        openModal={openInvoiceModal}
        selectedRefs={formData.invoiceRefNum}
        setSelectedRef={setSelectedInvoiceRefNum}
      />

      <Card small className="details-card my-4 w-100">
        <h4 className="m-0 section-title idStyle">Ref# {sheetRefNumber}</h4>
        <ListGroup flush>
          <ListGroupItem className="border-card p-3">
            <Row>
              <Col>
                <Form>
                  <Row form>

                    <Col lg="2" className="form-group p-2 m-0">
                      <label>Report Date</label>
                      <span
                        className='text-light form-control'>{setDateForTable(expenseSheet.date)}</span>
                    </Col>

                    <Col lg="3" className="form-group p-2 m-0">
                      <label>Report Name</label>
                      <InfoTooltip cssClassName="text-start custom-tooltip-inner"
                        msg="Suggested format is: Category-ExpenseReport-YYYYMMDD-hhmm" />
                      <span
                        className='text-light form-control'>{expenseSheet.description}</span>
                    </Col>

                    <Col sm="12" lg="7" className={`form-group p-2 mb-0  align-items-end ${expenseSheet.status === 'Reviewed'? 'justify-content-between' : 'justify-content-end'}`}>
                      
                      <Row>
                          <Col lg="3">
                            {expenseSheet.status === 'Reviewed' && <span className='pt-2'>Invoice Reference</span>}
                          </Col>
                          <Col lg="2">
                            { expenseSheet.status === 'Reviewed' &&  <span className='pt-2 px-0'>
                              {Number(formData.invoiceRefNum) === 0 ?
                                  <label className="primary-text"></label>
                                  : <Link to={`/new-invoice/${formData.invoiceRefNum}`}>{`INV-${formData.invoiceRefNum}`}</Link>
                                }
                              </span>}
                          </Col>
                          <Col lg="3">
                            {expenseSheet.status === 'Reviewed' &&  <Button className="p-2 m-2" onClick={toggleInvoice}>Find Invoice</Button>}
                          </Col>
                          <Col lg="4 px-0">
                            <span className="text-bold text-nowrap py-2">Expense Report Total: {numberToMonetary(expenseSheet.approvedReportTotal)}</span>
                          </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          {expenseSheet.status === 'Reviewed' && <span className='pt-2'>Payable Ref</span>}
                        </Col>
                        <Col lg="2">
                        {expenseSheet.status === 'Reviewed' && <span className='pt-2 px-0'>
                          {Number(formData.payRecordRefNum) === 0 ?
                                <label className="primary-text"></label>
                                //: <Link to={`/payable/${formData.payRecordRefNum}`}>{`PAY-${formData.payRecordRefNum}`}</Link>
                                : <a href="/payable" onClick={(e) => {navigateToPayable(e, formData.payRecordRefNum)}}
                                    onContextMenu={(e) => onContextMenuPayableChange(e, formData.payRecordRefNum)}>
                                    {`PAY-${formData.payRecordRefNum}`}
                                  </a>
                              }
                          </span>}
                        </Col>
                        <Col lg="3">
                          {expenseSheet.status === 'Reviewed' && <Button className="m-2 p-2" onClick={toggle}>Find Payable</Button>}
                        </Col>
                        <Col lg="4 px-0">
                            <span className="text-bold text-nowrap py-2">Expense Reject Total: {numberToMonetary(totalRejected)}</span>
                        </Col>
                      </Row>
                      </Col>
                      

                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>


      <ExpenseItemsCard
        expenseItemList={expenseSheet.expenseItemList}
        submittedItemTotal={String(expenseSheet.submittedItemTotal)}
        updateExpenseItemList={update_expense_item_list}
        rejectExpenseItem={reject_sub_expense_item}
        reviewExpenseItem={review_sub_expense_item}
        sheetRefNumber={sheetRefNumber}
        approveItemRoute={(["Approved", "Rejected", "Reviewed"].includes(expenseSheet.status)) ? "sub-review-expense-item" : "sub-approve-expense-item"}
        editableKeyToFocus={editableKeyToFocus}
        status={expenseSheet.status}
      />

      <MileageItemsCard
        mileageItemList={expenseSheet.mileageItemList}
        submittedMileageTotal={String(expenseSheet.submittedMileageTotal)}
        submittedYTD={expenseSheet.submittedYTD}
        approvedYTD={expenseSheet.approvedYTD}
        reviewedYTD={expenseSheet.reviewedYTD}
        totalYTD={expenseSheet.totalYTD}

        rejectMileageItem={reject_sub_mileage_item}
        reviewMileageItem={review_sub_mileage_item}
        sheetRefNumber={sheetRefNumber}
        approveItemRoute={(["Approved", "Rejected", "Reviewed"].includes(expenseSheet.status)) ? "sub-review-mileage-item" : "sub-approve-mileage-item"}
        status={expenseSheet.status}
      />

      <Card small className="my-4 w-100">
        <span className="tableHeader"><label htmlFor="notes"
          className="text-bold m-0">Notes</label></span>
        <ListGroup flush>
          <ListGroupItem className="border-card p-3">
            <Row>
              <Col>

                <Col sm="12" md="12" className="form-group p-2 m-0">
                  <FormTextarea
                    id="notes"
                    rows="5"
                    className="p-2"
                    name="notes"
                    autoFocus={"notes" === editableKeyToFocus.current}
                    onChange={onNotesChange}
                    value={apiNotes + notes}
                  />
                </Col>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>

      <Row className="px-3">
        <Col sm="12" md="12" lg="12"
          className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-between">
          <Button className="mx-1 py-2 mx-md-2" size="sm" theme="accent"
            onClick={() => { download_expense_invoice(sheetRefNumber) }}>Download PDF</Button>
          <div className='d-flex flex-row'>
            <div className="d-flex align-items-center">
              {(expenseSheet.status !== "Reviewed") ?
                <>
                  <InfoTooltip
                    cssClassName="text-start custom-tooltip-inner"
                    msg={"The Generate Invoice button will create the invoice, Payable and Payments for the Expense Report. Review button will send a notification to every user of this Expense Report."} />
                </> :
                <>
                  <InfoTooltip
                    cssClassName="text-start custom-tooltip-inner"
                    msg={"The Generate Invoice button will create the invoice, Payable and Payments for the Expense Report."} />
                </>}

              <Button className="mx-1 py-2 mx-md-2" size="sm" theme="accent"
                onClick={() => { generate_invoice_payrecord_payments(sheetRefNumber) }}>Generate Invoice</Button>
            </div>

            {(expenseSheet.status !== "Reviewed") &&
              <div className="d-flex align-items-center">
                {/* <InfoTooltip msg=" A notification will be sent to all the users of this expense report" /> */}
                <Button className="mx-1 py-2 mx-md-2" size="sm" theme="accent" onClick={reviewReport}>Review</Button>
              </div>
            }

            <Button className="mx-1 py-2 mx-md-2" size="sm"
              theme="accent" onClick={history.goBack}>Cancel</Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

SubscriberApproveExpenseReport.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  expenseSheet: PropTypes.object.isRequired,
  get_user_expense_sheet_details: PropTypes.func.isRequired,
  reject_sub_expense_report: PropTypes.func.isRequired,
  reject_sub_mileage_item: PropTypes.func.isRequired,
  reject_sub_expense_item: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
  expenseSheet: state.subExpenses.expenseSheetForSub,
  rejectedExpenseItems: state.subExpenses.rejectedExpenseItems,
  initialRejectedExpenseItems: state.subExpenses.initialRejectedExpenseItems,
  rejectedMileageItems: state.subExpenses.rejectedMileageItems,
  initialRejectedMileageItems: state.subExpenses.initialRejectedMileageItems,
  workerList: state.invoice.workerList,
});
export default connect(mapStateToProps, {
  sub_get_expense_report_for_review,
  review_sub_expense_report,
  reject_sub_expense_report,
  reject_sub_mileage_item,
  reject_sub_expense_item,
  approve_sub_mileage_item,
  review_sub_mileage_item,
  approve_sub_expense_item,
  review_sub_expense_item,
  update_expense_item_list,
  setAlert,
  link_invoice_payrecord_to_expense,
  generate_invoice_payrecord_payments,
  download_expense_invoice
})(SubscriberApproveExpenseReport);
