import React, { useEffect } from 'react'
import { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Card, Col, Container, DatePicker, Form, FormInput, FormSelect, ListGroup, ListGroupItem, Row } from 'shards-react';
import { get_country_list, get_region_list } from '../../../redux/actions/user';
import { STANDARD_FORMATS } from '../../../utils/constants';
import StandardFormInputFreeWidth from '../../../components/common/StandardFormInputFreeWidth';
import { get_employment_insurance_by_refnum, reset_employment_insurance_view, save_employment_insurance } from '../../../redux/actions/subscriber/rateTable';
import { setAPIDate, setInputDate } from '../../../utils/date';
import { setAlert } from '../../../redux/actions/alert';

const EmployementInsurance = ({
    employementInsuranceDetails, countryList, regionList, get_country_list, get_region_list,
    get_employment_insurance_by_refnum, save_employment_insurance, reset_employment_insurance_view,
    setAlert
}) => {
    const { EmploymentInsuranceRefNum } = useParams();
    const history = useHistory();
    const initialState = {
        refNum: 0,
        countryId: '',
        regionId: '',
        year: '',
        effectiveDate: '',
        endDate: '',
        yearlyMaximumInsurableEarnings: '',
        premiumRate: '',
        yearlyMaximumPremium: '',
    }

    const [formData, setFormData] = useState(initialState);
    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    var regionListOption = regionList.map((option) => {
        return <option value={option.stateProvId} key={option.stateProvName}>{option.stateProvName}</option>;
    });
    var countryListOption = countryList.map((option) => {
        return <option value={option.countryId} key={option.countryName}>{option.countryName}</option>;
    });

    useEffect(() => {
        if (formData.countryId) {
            get_region_list(formData.countryId);
        }
    }, [formData.countryId]);

    useEffect(() => {
        resetForm();
        get_country_list();
        if (EmploymentInsuranceRefNum > 0) {
            //Fetch the details by Id
            get_employment_insurance_by_refnum(EmploymentInsuranceRefNum);
        }
        return () => reset_employment_insurance_view();
    }, [])

    useEffect(() => {
        if (employementInsuranceDetails && Object.keys(employementInsuranceDetails).length !== 0) {
            setFormData({ ...getFormData(employementInsuranceDetails) })
        }
    }, [employementInsuranceDetails])

    const getFormData = (employementInsurance) => {
        return {
            refNum: employementInsurance.refNum || 0,
            countryId: employementInsurance.countryId || '',
            regionId: employementInsurance.regionId || '',
            year: employementInsurance.year || '',
            effectiveDate: setAPIDate(employementInsurance.effectiveDate),
            endDate: setAPIDate(employementInsurance.endDate),
            premiumRate: employementInsurance.premiumRate || '',
            yearlyMaximumInsurableEarnings: employementInsurance.yearlyMaximumInsurableEarnings || '',
            yearlyMaximumPremium: employementInsurance.yearlyMaximumPremium || '',
        }
    }

    function resetForm() {
        setFormData(initialState);
    }

    function resetAndBack() {
        resetForm();
        history.push('/employment-insurance');
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (e.nativeEvent.submitter.name === 'save') {
            //call an api to save
            let formDataToSubmit = { ...formData }
            formDataToSubmit.effectiveDate = setInputDate(formDataToSubmit.effectiveDate);
            formDataToSubmit.endDate = setInputDate(formDataToSubmit.endDate);
              let saved = await save_employment_insurance(JSON.stringify(formDataToSubmit));
              if (saved){
                history.replace(`/employment-insurance`);
              }
        }
        else {
            /// -> Copy and create new entry
            setAlert("Copied! Please save new entry.", "success")
            setFormData({ ...formData, refNum: 0 })
            history.push('/employment-insurance-add-edit/0');
        }
    }

    const onChangeStandardInput = (name, value) => setFormData({ ...formData, [name]: value });

    return (
        <>
            <Row className="w-100 mb-1 ml-n1" >
                <Col lg="12" className="mt-1 p-0">
                    <Card small className="mb-0 w-100" style={{ borderRadius: 0 }}>
                        <Row className="m-1">

                            <Col lg="12" className="d-flex flex-row justify-content-end py-2">
                                <Button className="mx-1 py-2 mx-md-2 btn btn-accent btn-sm" size="sm" theme="accent" name="copyCreateNewEntry" type="submit" form="frmEmployementInsurance">
                                    Copy & Create New Entry
                                </Button>
                                <Button className="mx-1 py-2 mx-md-2 btn btn-accent btn-sm" size="sm" theme="accent" type="submit" name="save" form="frmEmployementInsurance">
                                    Save
                                </Button>
                                <Button className="mx-1 py-2 mx-md-2 btn btn-accent btn-sm" size="sm" theme="accent" onClick={() => { resetAndBack() }}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Container fluid className="main-content-container p-4">
                <div className="w-100">
                    <Form id="frmEmployementInsurance" onSubmit={onSubmit}>
                        <Card small className="mb-5 w-100 p-1">
                            <h4 className="m-0 section-title idStyle">Ref# {EmploymentInsuranceRefNum}</h4>
                            <ListGroup flush>
                                <ListGroupItem className="border-card">
                                    <Row form>
                                        <Col lg="5" className="form-group p-2">
                                            <Row className="ml-2 form-group pt-2">
                                                <Col lg="4" className="form-group my-auto pt-2">
                                                    <label htmlFor="countryId" className="">Country</label>
                                                    <span aria-hidden="true" className="required "> *</span>
                                                </Col>
                                                <Col lg="8" className="form-group my-auto">
                                                    <FormSelect required={true} value={formData.countryId}
                                                        id="countryId" name='countryId' onChange={onChange}>
                                                        <option value=''>Select Country
                                                        </option>
                                                        {countryListOption}
                                                    </FormSelect>
                                                </Col>
                                            </Row>
                                            <Row className="ml-2 form-group pt-2">
                                                <Col lg="4" className="form-group my-auto pt-2">
                                                    <label htmlFor="regionId" className="">State/Province</label>
                                                    <span aria-hidden="true" className="required "> *</span>
                                                </Col>
                                                <Col lg="8" className="form-group my-auto">
                                                    <FormSelect required={true} value={formData.regionId}
                                                        id="regionId" name='regionId' onChange={onChange}>
                                                        <option value=''>Select State/Province
                                                        </option>
                                                        {regionListOption}
                                                    </FormSelect>
                                                </Col>
                                            </Row>

                                            <Row className="ml-2 form-group pt-2">
                                                <Col lg="4" className="form-group my-auto pt-2">
                                                    <label htmlFor="year" className="">Year</label>
                                                </Col>
                                                <Col lg="8" className="form-group my-auto">
                                                    <FormInput value={formData.year} id="year" name='year' onChange={onChange}
                                                        placeholder='Enter Year'>
                                                    </FormInput>
                                                </Col>
                                            </Row>
                                            <Row className="ml-2 form-group pt-2">
                                                <Col lg="4" className="form-group my-auto">
                                                    <label htmlFor="effectiveDate" className="">Effective Date</label>
                                                    <span aria-hidden="true" className="required "> *</span>
                                                </Col>
                                                <Col lg="8" className="form-group my-auto">
                                                    <DatePicker id='effectiveDate' autoComplete='off' name='effectiveDate' required
                                                        dateFormat='yyyy-MM-dd' placeholderText="YYYY-MM-DD"
                                                        value={formData.effectiveDate} selected={formData.effectiveDate}
                                                        onChange={(date) =>
                                                            setFormData({ ...formData, effectiveDate: date })
                                                        }
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className="ml-2 form-group pt-2">
                                                <Col lg="4" className="form-group my-auto pt-2">
                                                    <label htmlFor="endDate" className="">End Date</label>
                                                </Col>
                                                <Col lg="8" className="form-group my-auto">
                                                    <DatePicker id='endDate' autoComplete='off' name='endDate'
                                                        dateFormat='yyyy-MM-dd' placeholderText="YYYY-MM-DD"
                                                        value={formData.endDate} selected={formData.endDate}
                                                        onChange={(date) =>
                                                            setFormData({ ...formData, endDate: date })
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col lg="7" className="form-group p-2">
                                            <Row className="ml-2 form-group pt-2">
                                                <Col lg="6" className="form-group my-auto pt-2">
                                                    <label htmlFor="yearlyMaximumInsurableEarnings">Annual Maximum Insurable Earnings</label>
                                                    <span aria-hidden="true" className="required "> *</span>
                                                </Col>
                                                <Col lg="6" className="form-group my-auto">
                                                    <StandardFormInputFreeWidth id="yearlyMaximumInsurableEarnings" name='yearlyMaximumInsurableEarnings'
                                                        standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                        dataMaxFracDigits="2" type="text" required={true} 
                                                        value={formData.yearlyMaximumInsurableEarnings} onChangeStandardInput={onChangeStandardInput}
                                                        placeholder="0.00" align='right'
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className="ml-2 form-group pt-2">
                                                <Col lg="6" className="form-group my-auto pt-2">
                                                    <label htmlFor="premiumRate" className="">Premium Rate (%)</label>
                                                    <span aria-hidden="true" className="required "> *</span>
                                                </Col>
                                                <Col lg="6" className="form-group my-auto">
                                                    <StandardFormInputFreeWidth id="premiumRate" name='premiumRate'
                                                        standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                        dataMaxFracDigits="4" type="text" required={true} 
                                                        value={formData.premiumRate} onChangeStandardInput={onChangeStandardInput}
                                                        placeholder="0.0000" align='left'
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className="ml-2 form-group pt-2">
                                                <Col lg="6" className="form-group my-auto pt-2">
                                                    <label htmlFor="yearlyMaximumPremium" className="">Annual Maximum Premium</label>
                                                    <span aria-hidden="true" className="required "> *</span>
                                                </Col>
                                                <Col lg="6" className="form-group my-auto">
                                                    <StandardFormInputFreeWidth id="yearlyMaximumPremium" name='yearlyMaximumPremium'
                                                        standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                        dataMaxFracDigits="2" type="text" required={true} 
                                                        value={formData.yearlyMaximumPremium} onChangeStandardInput={onChangeStandardInput}
                                                        placeholder="0.00" align='right'
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            </ListGroup>
                        </Card>
                    </Form>
                </div>
            </Container>
        </>
    )
}

const mapStateToProps = (state) => ({
    countryList: state.user.countryList,
    regionList: state.user.regionList,
    employementInsuranceDetails: state.rateTable.employementInsuranceDetails,
});

export default connect(mapStateToProps, {
    get_country_list, get_region_list, reset_employment_insurance_view
    , save_employment_insurance, get_employment_insurance_by_refnum
    , setAlert
})
(EmployementInsurance)
