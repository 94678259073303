import api from "../../../utils/api";
import { download_file } from "../../../utils/general";
import { setAlert } from "../alert";
import { DELETE_EMPLOYMENT_INSURANCE, DELETE_EMPLOYMENT_INSURANCE_ERROR, DELETE_GOVERNMENT_PENSION, DELETE_GOVERNMENT_PENSION_2, DELETE_GOVERNMENT_PENSION_2_ERROR, DELETE_GOVERNMENT_PENSION_ERROR, DELETE_HEALTH_TAX, DELETE_HEALTH_TAX_ERROR, DELETE_WORKER_COMP_INSURANCE, DELETE_WORKER_COMP_INSURANCE_ERROR, GET_EMPLOYMENT_INSURANCE_BY_REFNUM, GET_EMPLOYMENT_INSURANCE_BY_REFNUM_ERROR, GET_EMPLOYMENT_INSURANCE_LIST, GET_EMPLOYMENT_INSURANCE_LIST_ERROR, GET_GOVERNMENT_PENSION_2_BY_REFNUM, GET_GOVERNMENT_PENSION_2_BY_REFNUM_ERROR, GET_GOVERNMENT_PENSION_2_LIST, GET_GOVERNMENT_PENSION_2_LIST_ERROR, GET_GOVERNMENT_PENSION_BY_REFNUM, GET_GOVERNMENT_PENSION_BY_REFNUM_ERROR, GET_GOVERNMENT_PENSION_LIST, GET_GOVERNMENT_PENSION_LIST_ERROR, GET_HEALTH_TAX_BY_REFNUM, GET_HEALTH_TAX_BY_REFNUM_ERROR, GET_HEALTH_TAX_LIST, GET_HEALTH_TAX_LIST_ERROR, GET_WORKER_COMP_INSURANCE_BY_REFNUM, GET_WORKER_COMP_INSURANCE_BY_REFNUM_ERROR, GET_WORKER_COMP_INSURANCE_LIST, GET_WORKER_COMP_INSURANCE_LIST_ERROR, RESET_EMPLOYMENT_INSURANCE, RESET_GOVERNMENT_PENSION, RESET_GOVERNMENT_PENSION_2, RESET_HEALTH_TAX, RESET_WORKER_COMP_INSURANCE } from "../types";

export const get_worker_comp_insurance_by_refnum = (workerCompRefNum) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/get-workers-comp?workerCompRefNum=${workerCompRefNum}`);

        dispatch({
            type: GET_WORKER_COMP_INSURANCE_BY_REFNUM,
            payload: res.data
        })
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({ type: GET_WORKER_COMP_INSURANCE_BY_REFNUM_ERROR })

        return false;
    }
}

export const save_worker_comp_insurance = (workercomp) => async (dispatch) => {
    try {
        const res = await api.post('api/subscriber-config/save-workers-comp', workercomp)
        dispatch(setAlert(res.data.message, 'success'));
        return true;
    } catch (err) {
        const success = err.response.data.success
        if (!success) {
            dispatch(setAlert(err.response.data.message, 'danger'))
        }
        return false;
    }
}

export const get_worker_comp_insurance_list = (
    wsibRate, industry, country, province, year, startDate, endDate
) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/get-workers-comp-list`,
            {
                params: {
                    wsibRate: wsibRate,
                    industry: industry,
                    countryId: country,
                    regionId: province,
                    year: year,
                    startDate: startDate,
                    endDate: endDate
                }
            }
        );
        dispatch({
            type: GET_WORKER_COMP_INSURANCE_LIST,
            payload: res.data,
        });
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: GET_WORKER_COMP_INSURANCE_LIST_ERROR,
        });
    }
};

export const delete_worker_comp_insurance_by_refnum = (workerCompRefNum) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/delete-workers-comp?workerCompRefNum=${workerCompRefNum}`);
        dispatch({
            type: DELETE_WORKER_COMP_INSURANCE,
            payload: res.data
        })
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({ type: DELETE_WORKER_COMP_INSURANCE_ERROR })

        return false;
    }
}

export const export_worker_comp_insurance = (wsibRate, industry, country, province, year, start, end) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/export-workers-comp`, {
            params: {
                wsibRate: wsibRate,
                industry: industry,
                countryId: country,
                regionId: province,
                year: year,
                startDate: start,
                endDate: end
            }
        }, {
            responseType: 'blob'
        })

        const contentDisposition = res.headers.get('Content-Disposition');
        let filename = 'downloaded_worker_comp' + '.csv';
        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
            const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
            if (filenameMatch.length === 2) {
                filename = filenameMatch[1];
            }
        }
        download_file(res.data, filename, 'application/octet-stream')
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            const text = await err.response.data.text();
            dispatch(setAlert(JSON.parse(text).message, "danger"));
        }
        return false;
    }
}

export const reset_worker_comp_view = () => (dispatch) => dispatch({ type: RESET_WORKER_COMP_INSURANCE })

// -----> Health Tax <----- // --NEED TO VERIFY
export const get_health_tax_by_refnum = (healthTaxRefNum) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/get-health-tax?healthTaxRefNum=${healthTaxRefNum}`);

        dispatch({
            type: GET_HEALTH_TAX_BY_REFNUM,
            payload: res.data
        })
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({ type: GET_HEALTH_TAX_BY_REFNUM_ERROR })

        return false;
    }
}

export const save_health_tax_rate = (healthTax) => async (dispatch) => {
    try {
        const res = await api.post('api/subscriber-config/save-health-tax', healthTax)
        dispatch(setAlert(res.data.message, 'success'));
        return true;
    } catch (err) {
        const success = err.response.data.success
        if (!success) {
            dispatch(setAlert(err.response.data.message, 'danger'))
        }
        return false;
    }
}

export const get_health_tax_list = (
    country, province, year, startDate, endDate
) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/get-health-tax-list?`,
            {
                params: {
                    countryId: country,
                    regionId: province,
                    year: year,
                    startDate: startDate,
                    endDate: endDate,
                }
            }
        );
        dispatch({
            type: GET_HEALTH_TAX_LIST,
            payload: res.data,
        });
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: GET_HEALTH_TAX_LIST_ERROR,
        });
    }
};

export const delete_health_tax_by_refnum = (refNum) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/delete-health-tax?healthTaxRefNum=${refNum}`);
        dispatch({
            type: DELETE_HEALTH_TAX,
            payload: res.data
        })
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({ type: DELETE_HEALTH_TAX_ERROR })

        return false;
    }
}

export const export_health_tax = (country, province, year, start, end) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/export-health-tax`, {
            params: {
                countryId: country,
                regionId: province,
                year: year,
                startDate: start,
                endDate: end,
            }
        }, {
            responseType: 'blob'
        })

        const contentDisposition = res.headers.get('Content-Disposition');
        let filename = 'downloaded_health_tax' + '.csv';
        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
            const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
            if (filenameMatch.length === 2) {
                filename = filenameMatch[1];
            }
        }
        download_file(res.data, filename, 'application/octet-stream')
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            const text = await err.response.data.text();
            dispatch(setAlert(JSON.parse(text).message, "danger"));
        }
        return false;
    }
}

export const reset_health_tax_view = () => (dispatch) => dispatch({ type: RESET_HEALTH_TAX })

// -----> Government Pension <----- //
export const get_government_pension_by_refnum = (governmentPensionRefNum) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/get-government-pension?governmentPensionRefNum=${governmentPensionRefNum}`);

        dispatch({
            type: GET_GOVERNMENT_PENSION_BY_REFNUM,
            payload: res.data
        })
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({ type: GET_GOVERNMENT_PENSION_BY_REFNUM_ERROR })

        return false;
    }
}

export const save_government_pension = (pensionObj) => async (dispatch) => {
    try {
        const res = await api.post('api/subscriber-config/save-government-pension', pensionObj)
        dispatch(setAlert(res.data.message, 'success'));
        return true;
    } catch (err) {
        const success = err.response.data.success
        if (!success) {
            dispatch(setAlert(err.response.data.message, 'danger'))
        }
        return false;
    }
}

export const get_government_pension_list = (country, province, year, startDate, endDate) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/get-government-pension-list`,
            {
                params: {
                    countryId: country,
                    regionId: province,
                    year: year,
                    startDate: startDate,
                    endDate: endDate
                }
            }
        );
        dispatch({
            type: GET_GOVERNMENT_PENSION_LIST,
            payload: res.data,
        });
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: GET_GOVERNMENT_PENSION_LIST_ERROR,
        });
    }
};

export const delete_government_pension_by_refnum = (refNum) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/delete-government-pension?governmentPensionRefNum=${refNum}`);
        dispatch({
            type: DELETE_GOVERNMENT_PENSION,
            payload: res.data
        })
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({ type: DELETE_GOVERNMENT_PENSION_ERROR })

        return false;
    }
}

export const export_government_pension = (country, province, year, start, end) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/export-government-pension`, {
            params: {
                countryId: country,
                regionId: province,
                year: year,
                startDate: start,
                endDate: end
            }
        }, {
            responseType: 'blob'
        })

        const contentDisposition = res.headers.get('Content-Disposition');
        let filename = 'downloaded_government_pension' + '.csv';
        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
            const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
            if (filenameMatch.length === 2) {
                filename = filenameMatch[1];
            }
        }
        download_file(res.data, filename, 'application/octet-stream')
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            const text = await err.response.data.text();
            dispatch(setAlert(JSON.parse(text).message, "danger"));
        }
        return false;
    }
}


export const reset_government_pension_view = () => (dispatch) => dispatch({ type: RESET_GOVERNMENT_PENSION })
// -----> Government Pension End <----- //

// -----> Employment Insurance <-----//
export const get_employment_insurance_by_refnum = (employmentInsuranceRefNum) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/get-employment-insurance?employmentInsuranceRefNum=${employmentInsuranceRefNum}`);

        dispatch({
            type: GET_EMPLOYMENT_INSURANCE_BY_REFNUM,
            payload: res.data
        })
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({ type: GET_EMPLOYMENT_INSURANCE_BY_REFNUM_ERROR })

        return false;
    }
}

export const save_employment_insurance = (employmentInsurance) => async (dispatch) => {
    try {
        const res = await api.post('api/subscriber-config/save-employment-insurance', employmentInsurance)
        dispatch(setAlert(res.data.message, 'success'));
        return true;
    } catch (err) {
        const success = err.response.data.success
        if (!success) {
            dispatch(setAlert(err.response.data.message, 'danger'))
        }
        return false;
    }
}

export const get_employment_insurance_list = (country, province, year, startDate, endDate) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/get-employment-insurance-list`,
            {
                params: {
                    countryId: country,
                    regionId: province,
                    year: year,
                    startDate: startDate,
                    endDate: endDate
                }
            }
        );
        dispatch({
            type: GET_EMPLOYMENT_INSURANCE_LIST,
            payload: res.data,
        });
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: GET_EMPLOYMENT_INSURANCE_LIST_ERROR,
        });
    }
};

export const delete_employment_insurance_by_refnum = (refNum) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/delete-employment-insurance?employmentInsuranceRefNum=${refNum}`);
        dispatch({
            type: DELETE_EMPLOYMENT_INSURANCE,
            payload: res.data
        })
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({ type: DELETE_EMPLOYMENT_INSURANCE_ERROR })

        return false;
    }
}

export const export_employment_insurance = (country, province, year, start, end) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/export-employment-insurance`, {
            params: {
                countryId: country,
                regionId: province,
                year: year,
                startDate: start,
                endDate: end
            }
        }, {
            responseType: 'blob'
        })

        const contentDisposition = res.headers.get('Content-Disposition');
        let filename = 'downloaded_employment_insurance' + '.csv';
        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
            const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
            if (filenameMatch.length === 2) {
                filename = filenameMatch[1];
            }
        }
        download_file(res.data, filename, 'application/octet-stream')
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            const text = await err.response.data.text();
            dispatch(setAlert(JSON.parse(text).message, "danger"));
        }
        return false;
    }
}

export const reset_employment_insurance_view = () => (dispatch) => dispatch({ type: RESET_EMPLOYMENT_INSURANCE })

// -----> Employment Insurance End <-----//

// -----> Government Pension 2 <-----//
export const get_government_pension_2_by_refnum = (governmentPension2RefNum) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/get-government-pension2?governmentPension2RefNum=${governmentPension2RefNum}`);

        dispatch({
            type: GET_GOVERNMENT_PENSION_2_BY_REFNUM,
            payload: res.data
        })
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({ type: GET_GOVERNMENT_PENSION_2_BY_REFNUM_ERROR })

        return false;
    }
}

export const save_government_pension_2 = (govtPension2) => async (dispatch) => {
    try {
        const res = await api.post('api/subscriber-config/save-government-pension2', govtPension2)
        dispatch(setAlert(res.data.message, 'success'));
        return true;
    } catch (err) {
        const success = err.response.data.success
        if (!success) {
            dispatch(setAlert(err.response.data.message, 'danger'))
        }
        return false;
    }
}

export const get_government_pension_2_list = (country, province, year, startDate, endDate) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/get-government-pension2-list`,
            {
                params: {
                    countryId: country,
                    regionId: province,
                    year: year,
                    startDate: startDate,
                    endDate: endDate
                }
            }
        );
        dispatch({
            type: GET_GOVERNMENT_PENSION_2_LIST,
            payload: res.data,
        });
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: GET_GOVERNMENT_PENSION_2_LIST_ERROR,
        });
    }
};

export const delete_government_pension_2_by_refnum = (refNum) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/delete-government-pension2?governmentPension2RefNum=${refNum}`);
        dispatch({
            type: DELETE_GOVERNMENT_PENSION_2,
            payload: res.data
        })
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({ type: DELETE_GOVERNMENT_PENSION_2_ERROR })

        return false;
    }
}

export const export_government_pension_2 = (country, province, year, start, end) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/export-government-pension2`, {
            params: {
                countryId: country,
                regionId: province,
                year: year,
                startDate: start,
                endDate: end
            }
        }, {
            responseType: 'blob'
        })

        const contentDisposition = res.headers.get('Content-Disposition');
        let filename = 'downloaded_government_pension2' + '.csv';
        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
            const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
            if (filenameMatch.length === 2) {
                filename = filenameMatch[1];
            }
        }
        download_file(res.data, filename, 'application/octet-stream')
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            const text = await err.response.data.text();
            dispatch(setAlert(JSON.parse(text).message, "danger"));
        }
        return false;
    }
}

export const reset_government_pension2_view = () => (dispatch) => dispatch({ type: RESET_GOVERNMENT_PENSION_2 })

// -----> Government Pension 2 End <-----//