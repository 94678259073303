import api from "../../../utils/api";
import { download_file } from "../../../utils/general";
import { setAlert } from "../alert";
import { BULK_UPDATE_INVOICE_STATUS, BULK_UPDATE_INVOICE_STATUS_ERROR, CALCULATE_INVOICE_ITEM_DETAILS, CALCULATE_INVOICE_ITEM_DETAILS_ERROR, DELETE_INVOICE_ITEM, GET_SUB_CURRENCY_LIST,
     GET_SUB_CURRENCY_LIST_ERROR, 
     GET_INVOICE, 
     GET_INVOICE_ERROR, 
     GET_INVOICE_ITEM, 
     GET_INVOICE_ITEM_ERROR, 
     GET_INVOICE_LIST, 
     GET_INVOICE_LIST_ERROR, 
     GET_INVOICE_STATUS_LIST, 
     GET_INVOICE_STATUS_LIST_ERROR, 
     GET_ITEM_TYPE_LIST, 
     GET_ITEM_TYPE_LIST_ERROR, 
     GET_WORKER_LIST_BY_CLIENT, 
     GET_WORKER_LIST_BY_CLIENT_ERROR,
     RESET_BULK_UPDATE_INVOICE_STATUS,
     RESET_INVOICE, 
     RESET_INVOICE_ITEM, 
     SAVE_INVOICE,
     SAVE_INVOICE_ERROR,
     SAVE_INVOICE_ITEM} from "../types";

export const get_currency_list = (
  ) => async (dispatch) => {
    try {
      const res = await api.get(`api/subscriber-config/currency-list`);
      dispatch({
        type: GET_SUB_CURRENCY_LIST,
        payload: res.data,
      });
    } catch (err) {
      const success = err.response.data.success;
      if (!success) {
        dispatch(setAlert(err.response.data.message, "danger"));
      }
      dispatch({
        type: GET_SUB_CURRENCY_LIST_ERROR,
      });
    }
  };
  export const save_invoice = (invoice
) => async (dispatch) => {
  try {
    const res = await api.post(`api/sub-invoice/save-invoice`, invoice);
    dispatch({
      type: SAVE_INVOICE,
      payload: res.data,
    });
    dispatch(setAlert(res.data.message, "success"));
    return true;
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_INVOICE_ERROR,
    });
    return false;
  }
};

export const get_invoice = (invoiceRefNum)=> async (dispatch)=>{
    try{
        const res = await api.get(`api/sub-invoice/get-invoice?invoiceRefNum=${invoiceRefNum}`);
        dispatch({
            type:GET_INVOICE,
            payload:res.data
        })
        
    }catch(err){
        const success = err.response.data.success;
        if(!success){
            dispatch(setAlert(err.response.data.message, 'danger'))
        }
        dispatch({type:GET_INVOICE_ERROR})
        
    }
}
export const delete_invoice_item = (invoiceItemRefNum)=> async (dispatch)=>{
    try{
        const res = await api.get(`api/sub-invoice/delete-invoice-item?invoiceItemRefNum=${invoiceItemRefNum}`);
        dispatch({type:DELETE_INVOICE_ITEM})
        dispatch(setAlert(res.data.message,'success'))
        return true;
    }catch(err){
        const success = err.response.data.success
        if(!success){
            dispatch(setAlert(err.response.data.message, 'danger'))
        }
        return false;
    }
}
export const save_invoice_item = (invoiceItem)=> async (dispatch)=>{
    try{
        const res = await api.post(`api/sub-invoice/save-invoice-item`,invoiceItem);
        dispatch({type:SAVE_INVOICE_ITEM,
          payload:res.data
        })
        dispatch(setAlert(res.data.message,'success'))
        return true;
    }catch(err){
        const success = err.response.data.success
        if(!success){
            dispatch(setAlert(err.response.data.message, 'danger'))
        }

        return false;
    }
}
export const reset_invoice = () => async (dispatch)=> dispatch({type:RESET_INVOICE})
export const get_item_type_list = ()=> async (dispatch)=>{
  try{
    const res = await api.get(`api/subscriber/get-itemtype-list`);
    dispatch({
      type:GET_ITEM_TYPE_LIST,
      payload:res.data
    })
  }catch(err){
    const success = err.response.data.success;
    if(!success){
      dispatch(setAlert(err.response.data.message,'danger'))
    }
    dispatch({
      type:GET_ITEM_TYPE_LIST_ERROR
    })
  }
}
export const get_worker_list_by_client = (clientId)=> async (dispatch)=>{
  try{
    const res = await api.get(`api/subscriber/client-worker-name-list?clientId=${clientId}`);
    dispatch({
      type:GET_WORKER_LIST_BY_CLIENT,
      payload:res.data
    })
  }catch(err){
    const success = err.response.data.success;
    if(!success){
      dispatch(setAlert(err.response.data.message,'danger'))
    }
    dispatch({
      type:GET_WORKER_LIST_BY_CLIENT_ERROR
    })
  }
}
export const calculate_invoice_item_details = ({itemTypeId, subTypeId, workerId, date,invoiceItemPayScheduleId, invoiceItemPayPeriodId})=> async (dispatch)=>{
  try{
    const res = await api.get(`api/sub-invoice/calculate-invoice-item-details`,{
      params:{
        itemTypeId:itemTypeId,
        subTypeId:subTypeId, 
        workerId:workerId, 
        date:date, 
        invoiceItemPayScheduleId:invoiceItemPayScheduleId,
        invoiceItemPayPeriodId:invoiceItemPayPeriodId
      }
    });
    dispatch({
      type:CALCULATE_INVOICE_ITEM_DETAILS,
      payload:res.data
    })
  }catch(err){
    const success = err.response.data.success;
    if(!success){
      dispatch(setAlert(err.response.data.message,'danger'))
    }
    dispatch({
      type:CALCULATE_INVOICE_ITEM_DETAILS_ERROR
    })
  }
}
export const get_invoice_item = (invoiceItemRefNum)=> async (dispatch)=>{
  try{
    const res = await api.get(`api/sub-invoice/get-invoice-item?invoiceItemRefNum=${invoiceItemRefNum}`);
    dispatch({
      type:GET_INVOICE_ITEM,
      payload:res.data
    })
  }catch(err){
    const success = err.response.data.success;
    if(!success){
      dispatch(setAlert(err.response.data.message,'danger'))
    }
    dispatch({
      type:GET_INVOICE_ITEM_ERROR
    })
  }
}
export const reset_invoice_item = ()=> async (dispatch)=>dispatch({type:RESET_INVOICE_ITEM})
export const get_invoice_list = ({
  from,
  to,
  clientName,
  invoiceRefNum,
  status,
  currency,
exported})=> async (dispatch)=>{
  try{
    const res = await api.get(`api/sub-invoice/get-invoice-list`,{
      params:{
        invoiceRefNum,
        clientName,
        status,
        currency,
        startDate:from,
        endDate:to,
        exported:exported
      }
    })
    dispatch({
      type:GET_INVOICE_LIST,
      payload:res.data
    })
  }catch(err){
    const success = err.response.data.success;
    if(!success){
      dispatch(setAlert(err.response.data.message,'danger'))
    }
    dispatch({
      type:GET_INVOICE_LIST_ERROR
    })
  }
}
export const get_invoice_status_list = ()=> async (dispatch)=>{
  try{
    const res = await api.get(`api/sub-invoice/get-invoice-status-list`)
    dispatch({
      type:GET_INVOICE_STATUS_LIST,
      payload:res.data
    })
  }catch(err){
    const success = err.response.data.success;
    if(!success){
      dispatch(setAlert(err.response.data.message,'danger'))
    }
    dispatch({
      type:GET_INVOICE_STATUS_LIST_ERROR
    })
  }
}
export const bulk_update_invoice_status = (bulkData)=> async (dispatch)=>{
  try{
    const res = await api.post(`api/sub-invoice/bulk-update-invoice-status`, bulkData)
    dispatch({
      type:BULK_UPDATE_INVOICE_STATUS
    })
    dispatch(setAlert(res.data.message,'success'))
    return true;
  }catch(err){
    const success = err.response.data.success;
    if(!success){
      dispatch(setAlert(err.response.data.message,'danger'))
    }
    dispatch({
      type:BULK_UPDATE_INVOICE_STATUS_ERROR
    })
    return false;
  }
}
export const reset_bulk_update = ()=> (dispatch)=> dispatch({type:RESET_BULK_UPDATE_INVOICE_STATUS})
export const set_bulk_update = ()=> (dispatch)=> dispatch({type:BULK_UPDATE_INVOICE_STATUS})

export const download_invoice = (refNum) => async (dispatch) => {
  try {
    const res = await api.get(`api/sub-invoice/download-invoice?invoiceRefNum=${refNum}`,{ 
      responseType: 'blob' 
    });

    const contentDisposition = res.headers.get('Content-Disposition');
    let filename = 'downloaded_payment_invoice_' + refNum + '.pdf';
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
      if (filenameMatch.length === 2) {
        filename = filenameMatch[1];
      }
    }
    download_file(res.data, filename)
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
}
export const export_iif = (refNums) => async (dispatch) => {
  try {
    const res = await api.post(`api/sub-invoice/export-quickbooks-iif`,refNums,{ 
      responseType: 'blob'
    });

    const contentDisposition = res.headers.get('Content-Disposition');
    let filename = 'exported_invoice_'  + '.iif';
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
      if (filenameMatch.length === 2) {
        filename = filenameMatch[1];
      }
    }
    download_file(res.data, filename, 'application/octatStream');
    return true;
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      const text = await err.response.data.text();
      dispatch(setAlert(JSON.parse(text).message, "danger"));
    }
    return false;
  }
}

export const download_intacct = (refList) => async (dispatch) => {
  try {
    console.log("Ref -=>", refList);
    const res = await api.post(`api/sub-invoice/export-sage-intacct`, refList,{ 
      responseType: 'blob' 
    });

    const contentDisposition = res.headers.get('Content-Disposition');
    let filename = 'downloaded_intacct_' + refList[0] + '.xlsx';
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
      if (filenameMatch.length === 2) {
        filename = filenameMatch[1];
      }
    }
    download_file(res.data, filename, 'application/octet-stream')
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
}
