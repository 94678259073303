import React from "react";
import PropTypes from "prop-types";
import {NavLink as RouteNavLink} from "react-router-dom";
import {
  NavItem,
  NavLink,
  DropdownMenu,
  DropdownItem,
  Collapse
} from "shards-react";
import classNames from "classnames";
import { withRouter } from 'react-router'
import {Dispatcher, Constants} from "../../../flux";

class SidebarSubNavItem extends React.Component {
  constructor(props) {
    super(props);
    
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  toggleDropdown(item) {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SUB_MENU_DROPDOWN,
      payload: item
    });
  }

  render() {
    const {item} = this.props;
    const hasSubItems = item.items && item.items.length;
    const location=this.props.location

    /// -> By Nipul Change the check from start to equals as we do have similar menu names which highlighting multiple menus
    //const isActive=item.activeRoutes?item.activeRoutes.some(route =>location.pathname.startsWith(route)):false;
    const isActive=item.activeRoutes?item.activeRoutes.some(route =>location.pathname === route):false;
    let navLinkClassNames=[]
    if(hasSubItems)navLinkClassNames.push("dropdown-toggle subItem-icon-wrapper pl-3 pr-1")
    if(isActive)navLinkClassNames.push("active")
    return (
      <NavItem style={{position: "relative"}}>
        <NavLink
          className={classNames(navLinkClassNames)}
          tag={hasSubItems ? "a" : RouteNavLink}
          to={hasSubItems ? "#" : item.to}
          onClick={() => this.toggleDropdown(item)}
        >
          {item.htmlBefore && (
            <div
              className="d-inline-block item-icon-wrapper ml-3"
              dangerouslySetInnerHTML={{__html: item.htmlBefore}}
            />
          )}
          {item.title && <span>{item.title}</span>}
          {item.htmlAfter && (
            <div
              className="d-inline-block item-icon-wrapper"
              dangerouslySetInnerHTML={{__html: item.htmlAfter}}
            />
          )}
        </NavLink>
        {hasSubItems && (
          
          <Collapse tag={DropdownMenu} small open={item.open} style={{top: 0}} className="">
            {item.items.map((subItem, idx) => {
              //const isSubItemActive=subItem.activeRoutes?subItem.activeRoutes.some(route =>location.pathname.startsWith(route)):false;
              const isSubItemActive=subItem.activeRoutes?subItem.activeRoutes.some(route =>location.pathname === route):false;
              return (
                <DropdownItem key={idx} tag={RouteNavLink} to={subItem.to} className={(isSubItemActive?"active submenu-dropdown":"submenu-dropdown")}>
                  {subItem.htmlBefore && (
                    <div
                      //className="d-inline-block subItem-icon-wrapper ml-2 mr-1 "
                      className="d-inline-block subItem-icon-wrapper"
                      dangerouslySetInnerHTML={{__html: subItem.htmlBefore}}
                    />
                  )}
                  {subItem.title}
                </DropdownItem>
              )
            })}
          </Collapse>
        )}
      </NavItem>
    );
  }
}

SidebarSubNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default withRouter(SidebarSubNavItem);
