/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useEffect,useState} from "react";
import {Link,useHistory,useParams} from "react-router-dom";

import {connect} from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  FormInput,
  Button, Alert
} from "shards-react";
import PropTypes from "prop-types";
import {SendVerification,VerifyCode,ResetPassword,clear_ForgotPasswordSteps} from "../redux/actions/user";

const ForgotPassword = ({SendVerification,VerifyCode,ResetPassword,clear_ForgotPasswordSteps, alerts,nextstep}) => {

  const [state, setState] = useState({
    showPassword:false,
    show2FA:false,
    showVerificationCode:false,
    show2FAPass:false,
    showNewPassword:false,
    showConfirmNewPassword:false,
    isMinimum8ch:false,
    is1Number:false,
    is1Uppercase:false,
    is1Lowercase:false,
    is1SpecialCh:false,
    isBothMatch:false,
    ForgotView:'email',
    username: '',
    newPassword: '',
    confirmPassword: '',
    twofa: '',
    code:''
  });
  clear_ForgotPasswordSteps();

  const history = useHistory()  
  
  const onChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  } 
  const passwordStrength = (e) => {  
  
    const passwordValue= e.target.value;    
    const passwordLength= passwordValue.length;
    const LowerRegExp = /[a-z]/;
    const UpperRegExp = /[A-Z]/;
    const NumberRegExp = /(?=.*?[0-9])/;;
    const SpecialCRegExp = /(?=.*?[#!@$%^&*])/;   

    var varMaxMatch=false;
    if(passwordValue.length>=8){
      varMaxMatch=true;
    }    
    var varMatch=false;
    if(passwordValue===state.confirmPassword){
      varMatch=true;
    }
   setState({...state, [e.target.name]: e.target.value,is1Number:NumberRegExp.test(passwordValue),is1Uppercase:UpperRegExp.test(passwordValue),is1Lowercase:LowerRegExp.test(passwordValue),is1SpecialCh:SpecialCRegExp.test(passwordValue),isMinimum8ch:varMaxMatch,isBothMatch:varMatch })
   
  }
  const passwordMatch = (e) => {    
    const passwordValue= e.target.value;
    var varMatch=false;
    if(passwordValue===state.newPassword){
      varMatch=true;
    }    
   setState({...state, [e.target.name]: e.target.value,isBothMatch:varMatch })
   
  }
  let SendVerificationEmail = (e) => {
    e.preventDefault();
    SendVerification(state.username);   
  }
  let Verify = (e) => {
    e.preventDefault();
    VerifyCode(state.username, state.code);   
  }
  let PasswordReset = (e) => {
    e.preventDefault();
    ResetPassword(state.username, state.newPassword, state.confirmPassword,state.code,state.twofa)
   }
  
  useEffect(() => {   
    if (nextstep && nextstep !== "" ){
    if(nextstep=="login"){
      history.push({
        pathname: "/login/success"
      });  
    }else{ setState({...state,ForgotView:nextstep})}
  }
  }, [nextstep]);

  const alertComponents = alerts.map((alert) => {
    return alert.msg && <Alert className="auth-form-errs rounded text-white text-center"
                  theme={alert.alertType} fade show>
      {alert.msg}
    </Alert>
  })
  return (
    <Container fluid
               className="main-content-container login-container h-100 px-1 py-4 py-md-0 px-lg-4">
      <Row noGutters className="h-100">
      { state.ForgotView=='email'&&
        <Col lg="4" md="5" className="auth-form mx-auto">
          <Card className={"mt-5"}>
            <CardBody>
            <div className="row justify-content-center">
            <label className="font-weight-bold">Forgot Password Request
            </label>
            </div>
            
            
              {alertComponents}
              <Row>
                <Col>
                <label className="text-center text-bold p-3">Enter your registered email address to receive a verification code.</label>
                </Col>
              </Row>
             

              {/* Form Fields */}
              <Form onSubmit={SendVerificationEmail}>
                <FormGroup className={"mb-1 p-3"}>
                  <label className={"text-bold"}
                         htmlFor="exampleInputEmail1">Username</label>
                  <FormInput
                    type="email"
                    id="email"
                    placeholder="Enter Email Address"
                    autoComplete="email"
                    name='username'
                    value={state.username}
                    onChange={onChange}
                    required
                  />
                </FormGroup>
               
                  
                  
                      
                    
                  <FormGroup className={"mb-1 p-3"}>
               
                
                   
                   <Button
                  theme="accent"
                  className="d-table mx-auto px-4 w-100 text-bold mb-1 text-uppercase login-btn"
                  type="submit"
                 
                >
                 SUBMIT
                </Button>
                   
                  
                  
                   <div className={"p-3 text-center"}>
                   <Link className={"mb-1"}  to={{pathname: "/login"}}>Back to Login</Link>
                   
                   </div>
               
                   </FormGroup>
                
              </Form>
            </CardBody>
          </Card>
        </Col>}
        {state.ForgotView=='code' &&
        <Col lg="4" md="5" className="auth-form mx-auto">
        <Card className={"mt-5"}>
            <CardBody>
            <div className="row justify-content-center">
            <label className="font-weight-bold">Verify Your Identity
            </label>
            </div>
            
        <div className="auth-form rounded bg-success text-white text-center p-2 mb-3">
           <label>Verification Code has been sent to your email. Please check your spam folders too. This is valid for 20 Minutes.</label>
          </div>
          
              {alertComponents}
              <Row>
                <Col>
                <label className="text-center text-bold p-3"> We want to make sure it’s really you. In order to verify your identity, enter the verification code sent to the registered email address.</label>
                </Col>
              </Row>
             

              {/* Form Fields */}
              <Form onSubmit={Verify}>
                <FormGroup className={"mb-1 p-3"}>
                  <label className={"text-bold"}
                         htmlFor="exampleInputEmail1">Verification Code</label>
                         <div className="input-group input-group-seamless" id="show_hide_password"> 
                  <FormInput
                     type={state.showVerificationCode?"text":"password"}
                    id="code"
                    placeholder="Enter Verification Code"
                    className={"form-control rounded-right"}
                    autoComplete="email"
                    name='code'
                    value={state.code}
                    onChange={onChange}
                    required
                  />
                   <div className="input-group-append">
                   <span class="input-group-text"><i onClick={e=>{setState({...state,showVerificationCode:!state.showVerificationCode})}} className={`fa ${state.showVerificationCode?"fa-eye":"fa-eye-slash"} text-secondary`} aria-hidden="true"/></span>
                    </div> 
                    </div>
                </FormGroup>
               
                  
                  
                      
                    
                  <FormGroup className={"mb-1 p-3"}>
               
                
                   
                   <Button
                  theme="accent"
                  className="d-table mx-auto px-4 w-100 text-bold mb-1 text-uppercase login-btn"
                  type="submit"
                >
                CONFIRM
                </Button>
                   
                  
                  
                   <div className={"p-3 text-center"}>
                   <Link className={"mb-1"}  to={{pathname: "/login"}}>Back to Login</Link>
                  
                   </div>
               
                   </FormGroup>
                
              </Form>
            </CardBody>
          </Card>
        </Col>}
        {state.ForgotView=='password' &&
        <Col lg="4" md="5" className="auth-form mx-auto">
        <Card className={"mt-5"}>
            <CardBody>
            <div className="row justify-content-center">
            <label className="font-weight-bold">Create New Password
            </label>
            </div>
            
             
              {alertComponents}
              <Row>
                <Col>
                <label className="text-center text-bold p-3"> Enter a unique and strong password.</label>
                </Col>
              </Row>
             

              {/* Form Fields */}
              <Form onSubmit={PasswordReset}>
              <FormGroup className={"mb-1"}>
                  <label className={"text-bold"}
                         htmlFor="newPassword">New Password</label>
                   <div className="input-group input-group-seamless" id="show_hide_password"> 
                     
                    <FormInput
                      type={state.showNewPassword?"text":"password"}
                      id="newPassword"
                      className={"form-control rounded-right"}
                      
                      placeholder="Enter New password"
                      autoComplete="current-password"
                      name='newPassword'
                      value={state.newPassword}
                      onChange={passwordStrength}
                      required
                    />
                     <div className="input-group-append">
                     <span class="input-group-text"><i onClick={e=>{setState({...state,showNewPassword:!state.showNewPassword})}} className={`fa ${state.showNewPassword?"fa-eye":"fa-eye-slash"} text-secondary`} aria-hidden="true"/></span>
                    </div> 
                   </div> 
                 
               {/* <label className={"mb-2"}>Please contact your Account Manager.</label> */}
                </FormGroup>
                <FormGroup className={"mb-1"}>
                  <label className={"text-bold"}
                         htmlFor="confirmPassword">Confirm New Password</label>
                   <div className="input-group input-group-seamless" id="show_hide_password"> 
                     
                    <FormInput
                      type={state.showConfirmNewPassword?"text":"password"}
                      id="confirmPassword"
                      className={"form-control rounded-right"}
                      placeholder="Confirm New password"
                      autoComplete="current-password"
                      name='confirmPassword'
                      value={state.confirmPassword}
                      onChange={passwordMatch}
                      required
                    />
                     <div className="input-group-append">
                     <span class="input-group-text"><i onClick={e=>{setState({...state,showConfirmNewPassword:!state.showConfirmNewPassword})}} className={`fa ${state.showConfirmNewPassword?"fa-eye":"fa-eye-slash"} text-secondary`} aria-hidden="true"/></span>
                    </div> 
                   </div> 
                 <div className="row">
                   <label className={"col-md-6 "} ><small><i className={"pr-2 fa "+ (state.isMinimum8ch ? 'fa-check-circle text-success' : 'fa-times-circle text-danger')}></i>Minimum 8 characters</small></label>
                      <label className={"col-md-6"}><small><i className={"pr-2 fa "+ (state.is1Number ? 'fa-check-circle text-success' : 'fa-times-circle text-danger')}></i>  1 number (0-9)</small></label>
                      <label className={"col-md-6"}><small><i className={"pr-1 fa "+ (state.is1Uppercase ? 'fa-check-circle text-success' : 'fa-times-circle text-danger')}></i> 1 uppercase letter (A-Z)</small></label>
                      <label className={"col-md-6"}><small><i className={"pr-2 fa "+ (state.is1Lowercase ? 'fa-check-circle text-success' : 'fa-times-circle text-danger')}></i>1 lowercase letter (a-z)</small></label>
                      <label className={"col-md-12 "}><small><i className={"pr-2 fa "+ (state.is1SpecialCh ? 'fa-check-circle text-success' : 'fa-times-circle text-danger')}></i>1 special character (!, @, #, $, %, ^, &, or *)</small></label>
                      <label className={"col-md-12"}><small><i className={"pr-2 fa "+ (state.isBothMatch ? 'fa-check-circle text-success' : 'fa-times-circle text-danger')}></i>Both passwords match</small></label>
                      </div>
                </FormGroup>
                  
                <FormGroup className={"mb-4"}>
                 
                 <label className={"text-bold mb-1 pr-5"} htmlFor="twofa">Two-Factor
                   authentication</label>
                 
                   <i onClick={e=>{setState({...state,show2FA:!state.show2FA})}} className={` twofa float-right fa ${state.show2FA?"fa-minus":"fa-plus"} btn-accent`} aria-hidden="true"/>
                   <label>Required when two-factor authentication is enabled</label>
                   {
                     state.show2FA && <div className="input-group" id="show_hide_eye">  
                     <div className="input-group input-group-seamless"> 
                     <FormInput
                     type={state.show2FAPass?"text":"password"}
                     placeholder="Enter Two-Factor authentication"
                     name='twofa'
                     className={"form-control rounded-right"}
                     value={state.twofa}
                     onChange={onChange}
                   />
                    <div className="input-group-append">
                    <span class="input-group-text"><i onClick={e=>{setState({...state,show2FAPass:!state.show2FAPass})}} className={`fa ${state.show2FAPass?"fa-eye":"fa-eye-slash"} text-secondary`} aria-hidden="true"/></span>
                   </div> 
                   </div>
                  </div> 
                   }
                 
               </FormGroup>
                  <FormGroup className={"mb-1 p-3"}>
                   <Button disabled={((state.isBothMatch && state.isMinimum8ch && state.is1Number && state.is1Uppercase && state.is1Lowercase && state.is1SpecialCh && state.newPassword !=='')?false:true)} theme="accent" className="d-table mx-auto px-4 w-100 text-bold mb-1 text-uppercase login-btn" type="submit"> CHANGE PASSWORD</Button>
                 
                   </FormGroup>
                
              </Form>
            </CardBody>
          </Card>
        </Col>}
      </Row>
    </Container>
  );
}

ForgotPassword.propTypes = {
  SendVerification: PropTypes.func.isRequired, 
  nextstep: PropTypes.string,
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  nextstep: state.user.nextstep,
  alerts: state.alert
});

export default connect(mapStateToProps, {SendVerification,VerifyCode,ResetPassword,clear_ForgotPasswordSteps})(ForgotPassword);
